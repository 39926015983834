a {
    text-decoration: none;
}

a,
a:visited {
    color: inherit;
}

article,
aside,
footer,
header,
nav,
section,
main {
    display: block;
}

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance:textfield;
}

input:focus::placeholder {
    color: transparent;
}

.MuiButton-text.header-category{
    font-size: 17px !important;
}